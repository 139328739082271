export class Board {
    id?:number;
    name?:string;
    shared?:string;
    status?:string;
    mine?:boolean;
    mode?:string;
    modified?:boolean;
    subscribers?: any[];

    constructor() {
        this.subscribers = new Array();
    }
}
