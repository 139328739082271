export class Card {
    id?:number;
    name?:string;
    content?:string;
    direction?:string;
    category_id?:number;
    category_name?:string;
    multiple?:boolean;
    editable?:boolean;
}
