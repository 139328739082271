export class User {
    id?: number;
    name?: string;
    surname?: string;
    email?: string;
    password?: string;
    imagefile?: any;
    organization?: string;
    year_of_birth?: number;
    role?: string;
    authcode?: string;
    active?: boolean;
    last_access?: Date;
    image_url?: string;
}
