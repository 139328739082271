import { Card } from "./card";

export class Category {
    id?: number;
    name?: string;
    cards?:Card[];

    constructor() {
        this.cards = new Array();
    }
}
